import React from "react";
import Activity from "../components/Activity";
import ContactButton from "../components/ContactButton";
import Header from "../components/Header";
import Navigation from "../components/Navigation";

const HomePage = () => {
  return (
    <div className="wrapper">
      <Header />
      <hr />
      <div className="content">
        <Activity />
        <Navigation />
      </div>
      <footer>
        <ContactButton />
        <div className="copyrights">© 2021 4DAO Limited</div>
      </footer>
    </div>
  );
};

export default HomePage;
