import React from "react";

const ContactButton = () => {
  return (
    <>
        <a className="button" href="mailto:info@4dao.com">CONTACT US HERE SMOOTH SKIN!</a>

    </>
  );
};

export default ContactButton;
