import React from "react";
import { ReactComponent as Logo } from "../assets/images/logo.svg";

const Header = () => {
  return (
    <header>
      <div className="logo left">
        <Logo />
      </div>
      <div className="site-title center">
        4DAO UNIFIED OPERATING SYSTEM <br />
        COPYRIGHT 2075-2077 4DAO LIMITED
        <br />
        -SERVER #1-
      </div>
      <div className="right"></div>
    </header>
  );
};

export default Header;
