import React from "react";

const Navigation = () => {
  return (
    <section className="navigation">
      <ul>
        <li>
          <a href="#">INTERNET</a>
        </li>
        <li>
          <a href="#">EMAIL</a>
        </li>
        <li>
          <a href="#">SAI</a>
        </li>
        <li>
          <a href="#">GREENFOOT</a>
        </li>
        <li>
          <a href="#">STEAM</a>
        </li>
        <li>
          <a href="#">UTILITIES</a>
        </li>
        <li>
          <a href="#">NETBEAMS</a>
        </li>
        <li>
          <a href="#">SKYPE</a>
        </li>
        <li>
          <a href="#">NOTEPAD</a>
        </li>
        <li>
          <a href="#">SYSTEM</a>
        </li>
      </ul>
    </section>
  );
};

export default Navigation;
