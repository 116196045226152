import React from "react";

const Activity = () => {
  return (
    <section className="activity">
      <ul>
        <li>&gt; We are Proof of Stake Validators</li>
          <br></br>
          <li>&gt; We are Blockchain Project Incubators</li>
          <br></br>
          <li>&gt; We offer Decentralized Web Infrastructure</li>
          <br></br>
        <li>&gt; We are Partners & Advisors</li>
      </ul>
    </section>
  );
};

export default Activity;
