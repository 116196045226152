import React from "react";
import HomePage from "./pages/HomePage";
import "./App.scss";

const App = () => {
  return (
    <>
      <HomePage />
    </>
  );
};

export default App;
